import React from 'react'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  // Text,
  // Grid,
  // Image,
  // List,
  // Button,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import { ServicesContent } from '../data'
import SEO from '../components/seo'
import {
  ServicesCard,
  fontSizes,
} from '../components/UtilityComponents'
const ServicesPage = () => {
  const fontColorHeading = useColorModeValue('brand.900', 'brand.1')
  // const backgroundColor = useColorModeValue('brand.100', 'brand.600')
  // const fontColor = useColorModeValue('brand.900', 'brand.1')
  // const fontColorSubHeading = useColorModeValue(
  //   'brand.700',
  //   'brand.100'
  // )
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )
  const headingCardColor = useColorModeValue('brand.700', 'cyan.200')
  ServicesContent.map((service, index) =>
    console.log(index + 1, service.name)
  )
  return (
    <>
      <SEO
        title="Services"
        keywords={ServicesContent.map(service => service.name)}
      />
      <Box>
        <Flex
          margin={'auto'}
          paddingY={[10]}
          paddingX={[3, 5, 10]}
          maxWidth="1400px"
          flexDirection={['column']}
          marginTop={[5]}
          // justifyContent={['space-between', 'space-evenly']}
          // alignItems={['center', 'center', 'center', 'center']}
          borderColor="yellow.900"
          borderWidth={0}
        >
          <Heading
            letterSpacing="wide"
            // textAlign={['center', 'left']}
            marginBottom={[5]}
            fontWeight="bold"
            // lineHeight="tall"
            textTransform={'uppercase'}
            maxW={['600px']}
            fontFamily="heading"
            color={fontColorHeading}
            fontSize={fontSizes.heading}
          >
            Services
          </Heading>
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {ServicesContent.map((service, index) => (
              <ServicesCard
                index={index + 1}
                key={index}
                fromIndexPage={false}
                name={service.name}
                description={service.description}
                types={service.types}
                imageName={service.imageName}
              />
            ))}
          </Flex>
          <Heading
            letterSpacing="wide"
            // textAlign={['center', 'left']}
            // marginTop={'2em'}
            fontWeight="medium"
            textTransform={'capitalize'}
            // lineHeight="tall"
            // maxW={['600px']}
            fontFamily="heading"
            color={headingCardColor}
            marginY={2}
            fontSize={fontSizes.subHeading}
            marginTop={[5]}
          >
            And most other financial services not listed above
          </Heading>
        </Flex>
      </Box>
    </>
  )
}
export default ServicesPage
